import en from './common/en.json';
import zhHans from './common/zh-Hans.json';
import zhHant from './common/zh-Hant.json';
import ja from './common/ja.json';
import ko from './common/ko.json';
import ru from './common/ru.json';

const commonResources = {
  en: { translation: en },
  'zh-CN': { translation: zhHans },
  'zh-HK': { translation: zhHant },
  ja: { translation: ja },
  ko: { translation: ko },
  ru: { translation: ru },
};

export default commonResources;
