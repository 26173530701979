import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonResources from './resources';

i18n
  .use(initReactI18next)
  .init({
    resources: commonResources,
    fallbackLng: {
      'zh-Hant': ['zh-Hans', 'zh', 'en'],
      'zh-Hans': ['zh-Hant', 'zh', 'en'],
      default: ['en'],
    },
    interpolation: {
      escapeValue: false,
      prefix: '%{',
      suffix: '}',
    },
    react: {
      bindI18nStore: 'added',
    },
  })

export default i18n;
